import React from "react";
import { graphql, Link, PageProps } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import "katex/dist/katex.min.css";

const BlogPostTemplate: React.FC<PageProps<GatsbyTypes.PostBySlugQuery>> = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { prev, next } = pageContext as any;
  return (
    (
      <Layout>
        <SEO pageTitle={post?.frontmatter?.title} pageDescription={post?.excerpt}/>

        <div>
          <h1>{post?.frontmatter?.title}</h1>
          <div>{post?.frontmatter?.date}</div>
          <div>{post?.frontmatter?.author}</div>
          <div dangerouslySetInnerHTML={{ __html: post?.html || ''  }}/>

          {prev &&
            <Link to={prev.frontmatter.path}>
              前の記事
            </Link>
          }
          {next &&
            <Link to={next.frontmatter.path}>
              次の記事
            </Link>
          }

        </div>
      </Layout>
    )
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query PostBySlug($pathSlug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      id
      excerpt(format: PLAIN)
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        author
      }
    }
  }
`;
